<template>
    <div class="sideMenu">
        <ul>
            <li v-for="(item,index) in sideItem" @click="changeSide(index)" :key = index :class="{active:sideMenu==index}"><span :style="{backgroundImage:`url(${item.icon})`}"></span>{{item.txt}}</li>
        </ul>
    </div>
</template>
<script>
import block from '@/assets/img/icon/myblock.svg'
import account from '@/assets/img/icon/account.svg'
import tash from '@/assets/img/icon/trash.svg'
import ship from '@/assets/img/icon/icon_ship.svg'
export default {
    data:()=>({
      sideItem:[
        {
            icon:ship,
            txt:'Go to islands'
        },
        {
            icon:block,
            txt:'My blocks'
        },
        {
            icon:account,
            txt:'Account'
        },
        // {
        //     icon:tash,
        //     txt:'Trash'
        // }
      ]  
    }),
    props:{
        sideMenu:{
            type: Number,
            default: 0,
        }
    },
    methods:{
        changeSide(idx){
            this.$emit('changeSide',idx)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
    .sideMenu{
        background-color:#fff;
        width:165px;
        height:calc(100vh - 64px);
        ul{
            padding-top: 15px;
            li{
                margin:0 auto 25px;
                width:140px;
                color:#333333;
                border-radius: 15px;
                height:30px;
                display: flex;
                align-items: center;
                padding: 0 15px;
                font-size: 14px;
                cursor: pointer;
                &:hover,&.active{
                    background-color:$lightBlue;
                }
                &:nth-of-type(1){
                    span{
                        width:12px;
                    }
                }
                span{
                    width:14px;
                    padding-bottom:14px;
                    background-size:100%;
                    margin-right: 10px;
                    display: inline-block;
                }
            }
        }
    }
</style>